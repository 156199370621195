<template>
<div>
    <div v-if="reportCardGrade">
        <span
            :id="key"
            class="btn btn-icon btn-solid mr-1"
            :class="[reportCardGrade.scaled ? reportCardGrade.scaled.color: '', `btn-md`]"
            @click="show = true"
        >
            <span class="course-icon align-items-center d-flex">
                {{ reportCardGrade.mark }}
            </span>
        </span>
    </div>
    <div v-else-if="progressAverage">
        <span
            :id="key"
            class="btn btn-icon mr-1"
            :class="[progressAverage.scaled.color, `btn-md`]"
            @click="show = true"
        >
            <span class="course-icon align-items-center d-flex">
                {{ progressAverage.scaled.mark }}
            </span>
        </span>
    </div>
    <div v-else>
        <span
            :id="key"
            class="btn btn-icon mr-1"
            :class="['Grey', `btn-md`]"
        >
            <span class="course-icon align-items-center d-flex">
                -
            </span>
        </span>
    </div>
    <b-popover
        :target="key"
        placement="bottom"
        :show.sync="show"
        custom-class="kt-popover"
    >
        <template #title>
            {{ markingPeriod.markingPeriodName }}
            <button
                class="btn btn-sm btn-icon btn-clean btn-icon-md pull-right mt-0"
                style="margin-top: -9px !important; margin-right: -9px !important;"
                @click.stop.prevent="show = false"
            >
                <i class="la la-close" />
            </button>
        </template>
        <div
            class="row"
            style="min-width: 200px"
        >
            <div class="col-12">
                <div class="kt-section mb-0">
                    <div class="kt-section__desc text-center">
                        {{ markingPeriod.markingPeriodRange }}
                    </div>
                </div>
            </div>
            <div
                v-if="reportCardGrade"
                class="col-6"
            >
                <div class="kt-section text-center">
                    <div class="kt-section__desc text-nowrap">
                        Report Card
                    </div>
                    <div class="kt-section__desc">
                        <router-link
                            :to="{
                                name: 'StudentCourseOverview',
                                params: {
                                    extCourseSectionId: course.extCourseSectionId,
                                    studentEnrollmentId,
                                }
                            }"
                            class="btn btn-icon btn-solid mr-1"
                            :class="[reportCardGrade.scaled.color, `btn-md`]"
                        >
                            <span class="course-icon align-items-center d-flex">
                                {{ reportCardGrade.mark }}
                            </span>
                        </router-link>
                        <div :class="[reportCardGrade.scaled.color, `font pt-2`]">
                            <template v-if="showPercentages">
                                {{ reportCardGrade.numericEquivalent }}%
                            </template>
                            {{ reportCardGrade.scaled.displayName }}
                        </div>
                    </div>
                </div>
            </div>
            <div :class="reportCardGrade ? 'col-6' : 'col-12'">
                <div
                    v-if="progressAverage"
                    class="kt-section text-center"
                >
                    <div class="kt-section__desc text-nowrap">
                        Progress Average
                    </div>
                    <div class="kt-section__desc">
                        <router-link
                            :to="{
                                name: 'StudentCourseOverview',
                                params: {
                                    extCourseSectionId: course.extCourseSectionId,
                                    studentEnrollmentId,
                                }
                            }"
                            class="btn btn-icon mr-1"
                            :class="[progressAverage.scaled.color, `btn-md`]"
                        >
                            <span class="course-icon align-items-center d-flex">
                                {{ progressAverage.scaled.mark }}
                            </span>
                        </router-link>

                        <div :class="[progressAverage.scaled.color, `font pt-2`]">
                            <template v-if="showPercentages">
                                {{ decimalToPercent(progressAverage.average) }}
                            </template>
                            {{ progressAverage.scaled.displayName }}
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="comments.length > 0"
                class="row mx-4 pt-2"
            >
                <div class="col-8">
                    <div class="kt-pagination kt-pagination--sm kt-pagination--info">
                        <div class="kt-pagination__toolbar">
                            <span class="pagination__desc">
                                Comment {{ selectedCommentIndex+1 }} of {{ comments.length }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-4 pull-right">
                    <div class="kt-pagination kt-pagination--sm kt-pagination--info">
                        <ul class="kt-pagination__links pull-right">
                            <li
                                class="kt-pagination__link--next"
                            >
                                <a
                                    href="#"
                                    @click.stop.prevent="previousComment"
                                >
                                    <i class="fa fa-angle-left kt-font-info" />
                                </a>
                            </li>
                            <li
                                class="kt-pagination__link--prev"
                            >
                                <a
                                    href="#"
                                    @click.stop.prevent="nextComment"
                                >
                                    <i class="fa fa-angle-right kt-font-info" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-12">
                    <div class="kt-section mb-0 pt-2">
                        <div
                            class="kt-section__content"
                            style="height: 60px"
                        >
                            {{ comment }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-popover>
</div>
</template>

<script lang="ts">

import { decimalToPercent } from '../store/mixins/utilMixins';
import { showPercentages, showScaledScores } from '../store/mixins/averagingMixins';

export default {
    name: 'StudentMarkingPeriodMark',
    props: {
        course: {
            type: Object,
            required: true,
        },
        studentMarkingPeriodAverage: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            show: false,
            selectedCommentIndex: 0,
        };
    },
    computed: {
        key() {
            return `studentMarkingPeriodAverage_${this.course.courseSectionId}_${this.markingPeriod.schoolTermMarkingPeriodId}`;
        },
        showPercentages() {
            return showPercentages(this.$store, this.course.gradeTemplate);
        },
        showScaledScores() {
            return showScaledScores(this.$store, this.course.gradeTemplate);
        },
        studentEnrollmentId() {
            return this.$route.params.studentEnrollmentId;
        },
        markingPeriod() {
            return this.studentMarkingPeriodAverage.markingPeriod;
        },
        progressAverage() {
            const { progressAverage } = this.studentMarkingPeriodAverage;
            return progressAverage && progressAverage.scaled ? progressAverage : null;
        },
        reportCardGrade() {
            const { reportCardGrade } = this.studentMarkingPeriodAverage;
            if (!reportCardGrade) return null;
            if (!reportCardGrade.scaled) return null;
            return reportCardGrade;
        },
        attendance() {
            return this.studentMarkingPeriodAverage.attendance;
        },
        comments() {
            // later on add in progress reports
            const { reportCardGrade } = this;
            if (!reportCardGrade) return [];
            const comments = [...reportCardGrade.comments];
            if (reportCardGrade.narrativeComment) {
                comments.unshift(reportCardGrade.narrativeComment);
            }
            return comments;
        },
        comment() {
            const { comments, selectedCommentIndex } = this;
            return comments[selectedCommentIndex];
        },
    },
    watch: {
        show() {
            const { show } = this;
            if (show) {
                // hide other open popovers
                this.$root.$emit('bv::hide::popover');
            }
        },
    },
    methods: {
        decimalToPercent(decimal) {
            return decimalToPercent(decimal);
        },
        nextComment() {
            const { selectedCommentIndex, comments } = this;
            if (selectedCommentIndex < comments.length - 1) {
                this.selectedCommentIndex += 1;
            }
        },
        previousComment() {
            const { selectedCommentIndex } = this;
            if (selectedCommentIndex > 0) {
                this.selectedCommentIndex -= 1;
            }
        },
    },
};
</script>

<style scoped src='../css/colors.scss' lang="scss" />

<style scoped>

span.course-icon {
    font-size: 1.3rem;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

div.kt-popover {
    z-index: 1001 !important;
}

</style>
