var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.reportCardGrade
        ? _c("div", [
            _c(
              "span",
              {
                staticClass: "btn btn-icon btn-solid mr-1",
                class: [
                  _vm.reportCardGrade.scaled
                    ? _vm.reportCardGrade.scaled.color
                    : "",
                  `btn-md`,
                ],
                attrs: { id: _vm.key },
                on: {
                  click: function ($event) {
                    _vm.show = true
                  },
                },
              },
              [
                _c(
                  "span",
                  { staticClass: "course-icon align-items-center d-flex" },
                  [_vm._v(" " + _vm._s(_vm.reportCardGrade.mark) + " ")]
                ),
              ]
            ),
          ])
        : _vm.progressAverage
        ? _c("div", [
            _c(
              "span",
              {
                staticClass: "btn btn-icon mr-1",
                class: [_vm.progressAverage.scaled.color, `btn-md`],
                attrs: { id: _vm.key },
                on: {
                  click: function ($event) {
                    _vm.show = true
                  },
                },
              },
              [
                _c(
                  "span",
                  { staticClass: "course-icon align-items-center d-flex" },
                  [_vm._v(" " + _vm._s(_vm.progressAverage.scaled.mark) + " ")]
                ),
              ]
            ),
          ])
        : _c("div", [
            _c(
              "span",
              {
                staticClass: "btn btn-icon mr-1",
                class: ["Grey", `btn-md`],
                attrs: { id: _vm.key },
              },
              [
                _c(
                  "span",
                  { staticClass: "course-icon align-items-center d-flex" },
                  [_vm._v(" - ")]
                ),
              ]
            ),
          ]),
      _c(
        "b-popover",
        {
          attrs: {
            target: _vm.key,
            placement: "bottom",
            show: _vm.show,
            "custom-class": "kt-popover",
          },
          on: {
            "update:show": function ($event) {
              _vm.show = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _vm._v(
                    " " + _vm._s(_vm.markingPeriod.markingPeriodName) + " "
                  ),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-sm btn-icon btn-clean btn-icon-md pull-right mt-0",
                      staticStyle: {
                        "margin-top": "-9px !important",
                        "margin-right": "-9px !important",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          _vm.show = false
                        },
                      },
                    },
                    [_c("i", { staticClass: "la la-close" })]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "row", staticStyle: { "min-width": "200px" } },
            [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "kt-section mb-0" }, [
                  _c("div", { staticClass: "kt-section__desc text-center" }, [
                    _vm._v(
                      " " + _vm._s(_vm.markingPeriod.markingPeriodRange) + " "
                    ),
                  ]),
                ]),
              ]),
              _vm.reportCardGrade
                ? _c("div", { staticClass: "col-6" }, [
                    _c("div", { staticClass: "kt-section text-center" }, [
                      _c(
                        "div",
                        { staticClass: "kt-section__desc text-nowrap" },
                        [_vm._v(" Report Card ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "kt-section__desc" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "btn btn-icon btn-solid mr-1",
                              class: [
                                _vm.reportCardGrade.scaled.color,
                                `btn-md`,
                              ],
                              attrs: {
                                to: {
                                  name: "StudentCourseOverview",
                                  params: {
                                    extCourseSectionId:
                                      _vm.course.extCourseSectionId,
                                    studentEnrollmentId:
                                      _vm.studentEnrollmentId,
                                  },
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "course-icon align-items-center d-flex",
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.reportCardGrade.mark) + " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              class: [
                                _vm.reportCardGrade.scaled.color,
                                `font pt-2`,
                              ],
                            },
                            [
                              _vm.showPercentages
                                ? [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.reportCardGrade.numericEquivalent
                                        ) +
                                        "% "
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.reportCardGrade.scaled.displayName
                                  ) +
                                  " "
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { class: _vm.reportCardGrade ? "col-6" : "col-12" }, [
                _vm.progressAverage
                  ? _c("div", { staticClass: "kt-section text-center" }, [
                      _c(
                        "div",
                        { staticClass: "kt-section__desc text-nowrap" },
                        [_vm._v(" Progress Average ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "kt-section__desc" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "btn btn-icon mr-1",
                              class: [
                                _vm.progressAverage.scaled.color,
                                `btn-md`,
                              ],
                              attrs: {
                                to: {
                                  name: "StudentCourseOverview",
                                  params: {
                                    extCourseSectionId:
                                      _vm.course.extCourseSectionId,
                                    studentEnrollmentId:
                                      _vm.studentEnrollmentId,
                                  },
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "course-icon align-items-center d-flex",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.progressAverage.scaled.mark) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              class: [
                                _vm.progressAverage.scaled.color,
                                `font pt-2`,
                              ],
                            },
                            [
                              _vm.showPercentages
                                ? [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.decimalToPercent(
                                            _vm.progressAverage.average
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.progressAverage.scaled.displayName
                                  ) +
                                  " "
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm.comments.length > 0
                ? _c("div", { staticClass: "row mx-4 pt-2" }, [
                    _c("div", { staticClass: "col-8" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "kt-pagination kt-pagination--sm kt-pagination--info",
                        },
                        [
                          _c("div", { staticClass: "kt-pagination__toolbar" }, [
                            _c("span", { staticClass: "pagination__desc" }, [
                              _vm._v(
                                " Comment " +
                                  _vm._s(_vm.selectedCommentIndex + 1) +
                                  " of " +
                                  _vm._s(_vm.comments.length) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "col-4 pull-right" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "kt-pagination kt-pagination--sm kt-pagination--info",
                        },
                        [
                          _c(
                            "ul",
                            { staticClass: "kt-pagination__links pull-right" },
                            [
                              _c(
                                "li",
                                { staticClass: "kt-pagination__link--next" },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.previousComment.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fa fa-angle-left kt-font-info",
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "li",
                                { staticClass: "kt-pagination__link--prev" },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.nextComment.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fa fa-angle-right kt-font-info",
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "col-12" }, [
                      _c("div", { staticClass: "kt-section mb-0 pt-2" }, [
                        _c(
                          "div",
                          {
                            staticClass: "kt-section__content",
                            staticStyle: { height: "60px" },
                          },
                          [_vm._v(" " + _vm._s(_vm.comment) + " ")]
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }